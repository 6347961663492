import axios from "axios";

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";

export let headers = {
  "Tenant-Access-Token": "YjUwOTIzZTYyNDU1YWM1ODNkYWZlMmU2YTQyMjQ5NGQ=",
  "Tenant-Access-Key": "zhdQaALEwP63CA",
};

export const api = axios.create({
  baseURL: BASE_URL,
  headers,
});
